<template>    
    <CRow>
        <CCol sm="12">           
            <TopHeadline :headerTitle="$t('pages.deposit.depositdetails')" :backTitle="$t('common.back')" :onGoBack="onBackPage">
                <template v-slot:headerRight v-if="depositDetail != null">

                    <button v-if="depositDetail.status == 0" type="button" name="confirm-deposit" class="btn btn-success" @click="onConfirmDeposit">
                        {{ $t('pages.deposit.moneytransferconfirmation') }}
                    </button>
                    <button v-if="depositDetail.status == 0" type="button" name="cancel-deposit" class="btn btn-success" @click="onRejectedDeposit">
                        {{ $t('common.rejected') }}
                    </button>                    
                </template>
            </TopHeadline>

            <Accordion :headerTitle="$t('pages.deposit.depositdetails')" v-if="depositDetail != null">
                <template v-slot:body>
                    <CRow>
                        <CCol sm="12">
                            <CForm>
                                <CInput :label="$t('pages.deposit.transactioncode')" :value="depositDetail.id" horizontal readonly></CInput>
                                <CInput :label="$t('pages.deposit.payerName')" :value="depositDetail.payerName" horizontal readonly></CInput>
                                <CInput :label="$t('pages.bank.accountNo')" :value="depositDetail.accountNo" horizontal readonly></CInput>
                                <CInput :label="$t('common.amount')" :value="depositDetail.amount" horizontal readonly></CInput>
                                <CInput :label="$t('common.createdDate')" :value="formatDate(depositDetail.createdDate)" horizontal readonly></CInput>
                                <CInput :label="$t('common.status')" :value="depostStatus(depositDetail.status)" horizontal readonly></CInput>

                                <div class="row">
                                    <div class="col-md-6">
                                        <label>{{ $t('pages.deposit.banktransactionproof') }}</label>
                                    </div>
                                    <div class="col-md-6">
                                        <img v-if="depositDetail.picture != null" :src="depositDetail.picture.thumbImageUrl" />
                                    </div>
                                </div>  
                                <CTextarea :placeholder="$t('pages.deposit.reasonForReject')" v-model="reasonForReject" v-if="depositDetail.status == 0" horizontal>
                                    <template #label>
                                        <div class="col-sm-3 col-form-label">
                                            {{ $t('pages.deposit.contents') }}
                                        </div>
                                    </template>
                                </CTextarea>
                            </CForm>
                        </CCol>

                    </CRow>
                </template>
            </Accordion>

            <Confirmation ref="confirmation"></Confirmation>
        </CCol>        
    </CRow>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex'
    import i18n from '@/plugins/i18n'
    import Confirmation from '@/components/Confirmation.vue'
    import Accordion from '@/components/Accordion.vue'
    import DepositStatus from '@/helpers/DepositStatus'
    import TopHeadline from '@/components/TopHeadline.vue'
    
    export default {
        name: 'DepositDetail',
		data() {            
			return {                                
                warningModal: false,
                reasonForReject: ''
            };
		},
        components: {           
            Confirmation,
            Accordion,
            TopHeadline
        },
        computed: {
            ...mapGetters('auth', ['user']),
            ...mapState('deposit', ['depositDetail']),
        },
        methods: {
            ...mapActions('deposit', ['getDepositById', 'changeDepositStatus']),
            formatDate(date) {
                if (date === undefined)
                    return '';
                return this.$moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss');
            },
            depostStatus(iStatus) {

                if (DepositStatus.Completed === iStatus)
                    return i18n.t('common.completed');
                else if (DepositStatus.Rejected === iStatus)
                    return i18n.t('common.rejected');
                else if (DepositStatus.Canceled === iStatus)
                    return i18n.t('common.canceled');
                else
                    return i18n.t('common.pending');

            },
            async onConfirmDeposit() {
                
                this.$refs.confirmation.show(i18n.t('pages.deposit.confirmAproveDeposit'), async () => {
                    var retVal = await this.changeDepositStatus({ userId: this.$store.state.auth.user.sub, depositId: this.depositDetail.id, newDepositStatus: DepositStatus.Completed });
                    if (retVal == true) {
                        this.onBackPage();
                    }
                });
            },
            async onRejectedDeposit() {
               
                this.$refs.confirmation.show(i18n.t('pages.deposit.confirmRejectDeposit'), async () => {

                    if (this.reasonForReject === '') {
                        this.$store.dispatch('toast/displayNotification', { text: i18n.t('pages.deposit.reasonForRejectContents') });
                    }
                    else {
                        var retVal = await this.changeDepositStatus({ userId: this.$store.state.auth.user.sub, depositId: this.depositDetail.id, newDepositStatus: DepositStatus.Rejected, reasonForReject: this.reasonForReject });
                        if (retVal == true) {
                            this.onBackPage();
                        }
                    }
                });
            },
            onBackPage() {               
                this.$router.push('/assets/deposits');
            },
		},       
        created() {
            this.getDepositById(this.$route.params.id);
        }
	}
</script>

